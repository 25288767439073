import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import ListingTableCache, { ListingTableData } from 'common/components/listingTable/ListingTableCache';
import TableActionButton from 'common/components/listingTable/actionButton/TableActionButton';
import { FilterData, ListingTableColumn, OrderData } from 'common/components/listingTable/models';
import { orderColumns } from 'common/components/listingTable/tableUtils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Logger from 'common/services/Logger'
import toast from 'react-hot-toast';
import ListingTable from 'common/components/listingTable/ListingTable';
import ListingTableSidebar from 'common/components/listingTable/sidebar/ListingTableSidebar';
import ConfirmDeleteModal from 'common/components/modal/confirmDeleteModal/ConfirmDeleteModal';
import PageBreadcrumbsPortal from 'common/components/pageBreadcrumbsPortal/PageBreadcrumbsPortal';
import PageContainer from 'common/components/pageContainer/PageContainer';
import PageHeader from 'common/components/pageHeader/PageHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Loading from 'common/services/Loading';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Button from 'common/components/button/Button';
import { ElementsSearchCriteria } from 'api/elements/Models/ElementsSearchCriteria';
import { ElementListDto } from 'api/elements/Models/ElementDto';
import ElementsService from 'api/elements/ElementsService';
import { SelectInputOption } from 'common/components/selectInput/SelectInput';
import TaxesService from 'api/taxes/TaxesService';
import UnitsService from 'api/units/UnitsService';
import WorkTypesService from 'api/workTypes/WorkTypesService';
import { UnitDto } from 'api/units/models/UnitDto';
import { TaxDto } from 'api/taxes/models/TaxDto';
import { ElementTypeDto } from 'api/elements/Models/ElementTypeDto';
import FamiliesService from 'api/families/FamiliesService';
import { FamilyDto } from 'api/families/models/FamilyDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { Image } from 'common/components/image/Image';
import InformationModal from 'common/components/modal/informationModal/InformationModal';
import UpdatePricesModal from '../modals/UpdatePricesModal';
import CheckInput from 'common/components/checkInput/CheckInput';
import styles from './ElementsListScreen.module.scss';
import { FamiliesListType } from 'screens/library/families/list/FamiliesListScreen';

interface Props {
    listType: FamiliesListType;
}

export function createPageId(listType: FamiliesListType) {
    return `LIBRARY_${listType}_LIST`
}

interface SubFamily {
    family: string ,
    subFamily: FamilyDto
}

function ElementsListScreen({ listType }: Props): JSX.Element | null {
    const PAGE_ID = createPageId(listType);
    const isElement = listType === 'elements';
    const isComponent = listType === 'components'
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<ListingTableData<ElementListDto, Partial<ElementsSearchCriteria>>>(ListingTableCache.get(PAGE_ID, {
        order: { field: 'name', orderColumn: 'e.name', isOrderAsc: true },
        hiddenColumns: ['elementTypeId', 'subFamilyId', 'reference', 'description', 'unitId', 'price', 'taxId'],
        columnsOrder: [],
        columnsWidth: [],
        page: 1,
        filters: {},
        lastRowId: null,
    }));
    const url = `/library/${listType}-items`;

    const [elements, setElements] = useState<ElementListDto[]>([]);
    const [elementToDelete, setElementToDelete] = useState<ElementListDto | null>(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(loggedUser?.policies ?? [], ['ELEMENTS_WRITE'])
    const canRead = UsersService.hasPolicies(loggedUser?.policies ?? [], ['ELEMENTS_READ'])
    const [refreshKey, setRefreshKey] = useState(0);
    const [scrollToRowId, setScrollToRowId] = useState<string | null>(null);
    const firstLoad = useRef(true);

    const [priceUpdateEnabled, setPriceUpdateEnabled] = useState(false);
    const [showUpdatePricesModal, setShowUpdatePricesModal] = useState(false);
    const [showConfirmUpdatePricesModal, setShowConfirmUpdatePricesModal] = useState(false);
    const [totalElementsUpdated, setTotalElementsUpdated] = useState<number>(0);
    const [selectedAllPriceToUpdate, setSelectedAllPriceToUpdate] = useState<boolean>(false);
    const [allElementsToUpdate, setAllElementsToUpdate] = useState<string[]>([]);

    const [elementTypes, setElementTypes] = useState<SelectInputOption[]>()
    const [workTypes, setWorkTypes] = useState<SelectInputOption[]>()
    const [units, setUnits] = useState<SelectInputOption[]>()
    const [taxes, setTaxes] = useState<SelectInputOption[]>()
    const [families, setFamilies] = useState<SelectInputOption[]>()
    const [subFamilies, setSubFamilies] = useState<SelectInputOption[]>()
    const [selectedIdsToUpdatePrice, setSelectedIdsToUpdatePrice] = useState<string[]>([]);

    const handlePriceToUpdateFilterChange = (value: boolean) => {
        setSelectedAllPriceToUpdate(value);
        setElements(prevElements => prevElements.map(element => ({
            ...element,
            priceToUpdate: value
        })));
    };

    const handleElementCheckboxChange = (id: string) => {
        setSelectedIdsToUpdatePrice(prevIds => {
            // Verifica se o ID já está na lista de selecionados
            if (prevIds.includes(id)) {
                // Se já estiver, remove-o
                return prevIds.filter(existingId => existingId !== id);
            } else {
                // Se não estiver, adiciona-o
                return [...prevIds, id];
            }
        });
        setElements(prevElements => {
            const updatedElements = prevElements.map(element =>
                element.id === id ? { ...element, priceToUpdate: !element.priceToUpdate } : element
            );

            // if all elements are selected
            const allSelected = updatedElements.every(element => element.priceToUpdate) && !tableData.filters;
            setSelectedAllPriceToUpdate(allSelected);
            return updatedElements;
        });
    };

    const renderUpdatePricesColumn: ListingTableColumn<ElementListDto> = {
        name: t('elements.prices_update.update_price'),
        preventClick: true,
        hideOrderButton: true,
        field: 'priceToUpdate',
        minWidth: '5rem',
        filter: { type: 'checkbox', value: selectedAllPriceToUpdate, onChange: (event) => handlePriceToUpdateFilterChange(event.target.checked), notIncludeInTotalFilters: true },
        renderCell: (row) => <div>
            <CheckInput
                checked={row.priceToUpdate}
                key={row.id}
                onChange={() => handleElementCheckboxChange(row.id)}
            />
        </div>,
    };

    const columns: ListingTableColumn<ElementListDto>[] = orderColumns([
        ...(priceUpdateEnabled ? [renderUpdatePricesColumn] : []),
        {
            name: isElement ? t('elements.element_type') : t('elements.ouvrage_type') ,
            field: 'elementTypeId',
            minWidth: '18rem',
            filter: { type: 'dropdown', value: tableData.filters.elementTypeId ?? '', options: elementTypes || [] },
            orderColumn: 'et.Name',
            renderCell: (row) => row.elementTypeName ?? ''
        },
        {
            name: t('elements.work_type'),
            field: 'workTypeId',
            minWidth: '18rem',
            filter: { type: 'dropdown', value: tableData.filters.workTypeId ?? '', options: workTypes || [] },
            orderColumn: 'wt.Name',
            renderCell: (row) => row.workTypeName ?? ''
        },
        {
            name: t('elements.family'),
            field: 'familyId',
            minWidth: '18rem',
            filter: { type: 'dropdown', value: tableData.filters.familyId ?? '', options: families || [] },
            hideOrderButton: true,
            renderCell: (row) => renderFamilies(row.familiesNames)
        },
        {
            name: t('elements.sub_family'),
            field: 'subFamilyId',
            minWidth: '18rem',
            filter: { type: 'dropdown', value: tableData.filters.subFamilyId ?? '', options: subFamilies || [] },
            hideOrderButton: true,
            renderCell: (row) => renderFamilies(row.subFamiliesNames)
        },
        {
            name: t('elements.image'),
            field: 'mediaUrl',
            hideOrderButton: true,
            renderCell: (row) => <Image src={row.mediaUrl} containerStyle={{ maxWidth: 100, width: 'auto' }} />
        },
        {
            name: t('elements.name'),
            field: 'name',
            minWidth: '18rem',
            filter: { type: 'text', value: tableData.filters.name ?? '', placeholder: t('elements.name') },
        },
        {
            name: t('elements.reference'),
            field: 'reference',
            minWidth: '18rem',
            filter: { type: 'text', value: tableData.filters.reference ?? '', placeholder: t('elements.reference') },
        },
        {
            name: t('elements.description'),
            field: 'description',
            minWidth: '18rem',
            filter: { type: 'text', value: tableData.filters.description ?? '', placeholder: t('elements.description') },
            renderCell: (row) => row.description ? <div dangerouslySetInnerHTML={{ __html: row.description }}></div> : ''
        },
        {
            name: t('elements.unit'),
            field: 'unitId',
            minWidth: '18rem',
            filter: { type: 'dropdown', value: tableData.filters.unitId ?? '', options: units || [] },
            orderColumn: 'u.Name',
            renderCell: (row) => row.unitName ? (row.unitName + ' - ' + row.unitSymbol) : ''
        },
        {
            name: t('elements.price_no_tax'),
            field: 'price',
            minWidth: '18rem',
            cellFormat: 'money',
            cellAlignment: 'right',
            filter: { type: 'money', value: tableData.filters.price, unitPrefix: '€' },
            suffix: '€'
        },
        {
            name: t('elements.tax'),
            field: 'taxId',
            minWidth: '18rem',
            filter: { type: 'dropdown', value: tableData.filters.taxId ?? '', options: taxes || [] },
            orderColumn: 't.Description',
            renderCell: (row) => row.taxName ? (row.taxValue + '% - ' + row.taxName) : ''
        },
        {
            name: t('common.updated_date'),
            field: 'updatedDate',
            filter: {
                type: 'date-range',
                value: {
                    start: tableData.filters.updatedDate?.start ? new Date(tableData.filters.updatedDate.start) : null,
                    end: tableData.filters.updatedDate?.end ? new Date(tableData.filters.updatedDate.end) : null
                }
            },
            minWidth: '22rem',
            orderColumn: 'updated_date',
            renderCell: (row) => {
                return row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAULT} /> : '';
            }
        },
        {
            name: t('common.user_updated'),
            field: 'userUpdatedName',
            filter: { type: 'text', value: tableData.filters.userUpdatedName ?? '', placeholder: t('common.user_updated') },
            minWidth: '21rem',
            orderColumn: 'user_updated_name',
        },
    ], tableData.columnsOrder, [{ field: 'priceToUpdate', position: 0 }]);

    const renderAction = (row: ElementListDto) => (
        <TableActionButton
            options={[
                { label: <span><FaEdit /> {t('common.edit')}</span>, onClick: () => navigateTo('edit', row.id) },
                { label: <span><FaCopy /> {t('common.duplicate')}</span>, onClick: () => navigateTo('duplicate', row.id) },
                { label: <span><FaTrash /> {t('common.delete')}</span>, onClick: () => onOpenModalToDelete(row) },
            ]}
        />
    )

    const renderFamilies = (item: string[]) => {
        const cleanItem = item.filter(x => x != null);

        if (cleanItem.length === 0) {
            return ''
        }
        return (
            <div>{cleanItem.map((x, i) => {
                return (<div key={i}>{x}<br /></div>)
            })}</div>
        )
    }

    useEffect(() => {
        void getData();
    }, [tableData.page, tableData.filters, tableData.order, refreshKey]);

    useEffect(() => {
        ListingTableCache.save(PAGE_ID, tableData);
    }, [tableData]);

    const getData = async (pricesWereUpdated?: boolean) => {
        if (isLoading) {
            return;
        }
        // Loading.show()
        try {
            setIsLoading(true);

            const elementsData = await ElementsService.getList({
                ...tableData.filters,
                isComponent,
                isElement,
                page: ((firstLoad.current && tableData.page > 1) || pricesWereUpdated) ? 1 : tableData.page,
                itemsPerPage: ((firstLoad.current && tableData.page > 1) || pricesWereUpdated) ? DEFAULT_PAGINATION_ITEMS_PER_PAGE * tableData.page : DEFAULT_PAGINATION_ITEMS_PER_PAGE,
                orderColumn: tableData.order.orderColumn,
                orderBy: tableData.order.isOrderAsc ? 'asc' : 'desc'
            });

            const updatedItems = elementsData.items.map(item => {
                const existingElement = selectedIdsToUpdatePrice.find(x => x === item.id);
                return {
                    ...item,
                    priceToUpdate: selectedAllPriceToUpdate ?? !!existingElement // Mantém o estado anterior
                };
            });
            const newListElements = elementsData.currentPage === 1 ? updatedItems : [...elements, ...updatedItems];

            setHasMore(elementsData.totalItems > newListElements.length);
            setElements(newListElements);
            setTotalItems(elementsData.totalItems);

            if (firstLoad.current) {
                const [
                    unitsData,
                    taxesData,
                    workTypesData,
                    familiesData,
                    elementTypesData,
                ] = await Promise.all([
                    UnitsService.getFormattedCatalog(),
                    TaxesService.getFormattedCatalog(),
                    WorkTypesService.getCatalog(),
                    FamiliesService.getElementsCatalog({ isComponent,isElement }),
                    ElementsService.getElementTypesCatalog()
                ]);

                setUnits(unitsData.map((x: UnitDto) => {
                    return {
                        label: x.name,
                        value: x.id,
                    }
                }))
                setTaxes(taxesData.map((x: TaxDto) => {
                    return {
                        label: x.description,
                        value: x.id
                    }
                }))
                setWorkTypes(workTypesData)
                setElementTypes(elementTypesData.map((x: ElementTypeDto) => {
                    return {
                        label: x.name,
                        value: x.id
                    }
                }))

                setFamilies(familiesData.map((x: FamilyDto) => {
                    return {
                        label: x.name,
                        value: x.id
                    }
                }))

                const subFamiliesArray: SubFamily[] = [];

                familiesData.forEach(x => {
                    if (x.subFamilies) {
                        x.subFamilies.forEach(y => {
                            subFamiliesArray.push({ family: x.name , subFamily: y })
                        });
                    }
                });

                setSubFamilies(subFamiliesArray.map((x: SubFamily) => {
                    return {
                        label: x.subFamily.name,
                        value: x.subFamily.id,
                        subTitle: x.family
                    }
                }))

                setScrollToRowId(tableData.lastRowId);
            }
            firstLoad.current = false;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get elements', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            setIsLoading(false);
            Loading.hide()
        }
    }

    const navigateTo = (type: string, id?: string) => {
        if (type === 'create') {
            navigate(`${url}/${type}`);
        } else if (id) {
            ListingTableCache.saveLastRowId(PAGE_ID, id);
            navigate(`${url}/${type}/${id}`);
        }
    }

    const onOpenModalToDelete = (row: ElementListDto) => {
        setElementToDelete(row);
        setShowConfirmDeleteModal(true)
    }

    const onDelete = async (result: boolean) => {
        if (!result) {
            setShowConfirmDeleteModal(false)
            return;
        }
        try {
            await ElementsService.remove(elementToDelete as ElementListDto)
            setTableData(d => ({ ...d, page: 1 }));
            setRefreshKey(k => k + 1);
            Loading.hide();
            toast.success(t('messages.record_delete_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete tax', error);
            toast.error(t('messages.record_delete_error'));
        }
        setShowConfirmDeleteModal(false)
    }

    const onLoadMore = () => {
        setTableData(d => ({ ...d, page: d.page + 1 }));
    }

    const onFilter = (col: ListingTableColumn<ElementListDto>, filterData: FilterData) => {
        if (col.field === 'priceToUpdate') {
            handlePriceToUpdateFilterChange(filterData.value as boolean);
            return;
        }
        setTableData(d => {
            const data = ({
                ...d, page: 1
            })
            switch (col.field) {
                case 'elementTypeId':
                    data.filters = {
                        ...data.filters,
                        elementTypeId: filterData.value as string,
                    };
                    break;
                case 'workTypeId':
                    data.filters = {
                        ...data.filters,
                        workTypeId: filterData.value as string,
                    };
                    break;
                case 'familyId':
                    data.filters = {
                        ...data.filters,
                        familyId: filterData.value as string,
                    };
                    break;
                case 'subFamilyId':
                    data.filters = {
                        ...data.filters,
                        subFamilyId: filterData.value as string,
                    };
                    break;
                case 'name':
                    data.filters = {
                        ...data.filters,
                        name: filterData.value as string,
                    };
                    break;
                case 'reference':
                    data.filters = {
                        ...data.filters,
                        reference: filterData.value as string,
                    };
                    break;
                case 'description':
                    data.filters = {
                        ...data.filters,
                        description: filterData.value as string,
                    };
                    break;
                case 'unitId':
                    data.filters = {
                        ...data.filters,
                        unitId: filterData.value as string,
                    };
                    break;
                case 'price':
                    data.filters = {
                        ...data.filters,
                        price: filterData.value as number,
                    };
                    break;
                case 'taxId':
                    data.filters = {
                        ...data.filters,
                        taxId: filterData.value as string,
                    };
                    break;

                case 'updatedDate':
                    data.filters = {
                        ...data.filters,
                        updatedDate: filterData.value,
                        updatedDateStart: filterData.value?.start as Date,
                        updatedDateEnd: filterData.value?.end as Date
                    };
                    break;

                case 'userUpdatedName':
                    data.filters = {
                        ...data.filters,
                        userUpdatedName: filterData.value as string
                    };
                    break;
            }
            return data;
        });

        setRefreshKey(k => k + 1);
    }

    const onOrder = (orderData: OrderData<ElementListDto>) => {
        setRefreshKey(k => k + 1)
        setTableData(d => ({ ...d, order: orderData, page: 1 }));
    }

    const onClearFilters = () => {
        setTableData(d => ({ ...d, page: 1, filters: {}, lastRowId: null }));
        setRefreshKey(k => k + 1)
    }

    // #region  updatePrices

    const getAllElementsIds = async () => {
        try {
            setIsLoading(true);
            const elementsData = await ElementsService.getAllByCriteria({ ...tableData.filters });
            if (elementsData.length) {
                const elementsIds = elementsData.map(x => x.id);
                setAllElementsToUpdate(elementsIds);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get all elements', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            setIsLoading(false);
            Loading.hide();
        }
    };

    const onUpdatePricesModalOpen = async () => {
        if (selectedAllPriceToUpdate && hasMore) {
            await getAllElementsIds();
        } else {
            setAllElementsToUpdate(elements.filter(x => x.priceToUpdate).map(x => x.id));
        }
        setShowUpdatePricesModal(true);
    };

    const onUpdatePricesModalClose = async (priceVariationPercentage: number | null | undefined) => {
        if (!priceVariationPercentage) {
            setShowUpdatePricesModal(false);
            setPriceUpdateEnabled(false);
            setSelectedAllPriceToUpdate(false);
            return;
        }
        void updatePrices(allElementsToUpdate, priceVariationPercentage);
    }

    const updatePrices = async (elementsIds: string[], value: number) => {
        try {
            const elementsToUpdate = {
                elementsIds,
                priceVariationPercentage: value
            };
            await ElementsService.updatePrices(elementsToUpdate)
            setTotalElementsUpdated(elementsIds.length);
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t update elements prices', error);
            toast.error(t('messages.record_save_success'));
        }
        setShowUpdatePricesModal(false);
        setShowConfirmUpdatePricesModal(true);
    }

    const onConfirmUpdatePricesModalClose = () => {
        setShowConfirmUpdatePricesModal(false);
        setPriceUpdateEnabled(false);
        setSelectedAllPriceToUpdate(false);
    }

    useEffect(() => {
        if (!showConfirmUpdatePricesModal) {
            void getData(true);
        }
    }, [showConfirmUpdatePricesModal]);

    // #endregion

    if (!canRead) {
        return null;
    }
    const title = isElement ? t('elements.title_elements') : t('elements.title_components');
    return (<ScreenTitle title={title}>
        <PageBreadcrumbsPortal
            breadcrumbs={[
                { name: t('home.title'), url: '/' },
                { name: title, url },
            ]}
        />
        <PageHeader title={title} subTitle={`${totalItems} ${t('common.total_results')}`} informationText={t('taxes.list.sub_title')} showGoBack={false} addSidebarSpacing >
            <Button variant='secondary' className={styles.leftButton} onClick={() => {
                setPriceUpdateEnabled(!priceUpdateEnabled);
                if (!priceUpdateEnabled) {
                    setShowConfirmUpdatePricesModal(false);
                    handlePriceToUpdateFilterChange(false);
                }
            }} >
                {priceUpdateEnabled ? t('elements.prices_update.cancel_selected_elements') : t('elements.prices_update.update_price')}
            </Button>

            {priceUpdateEnabled && <Button onClick={() => { onUpdatePricesModalOpen() }
            }>{t('elements.prices_update.confirm_selected_elements')}</Button>}
            {canWrite && !priceUpdateEnabled && <Button onClick={() => navigateTo('create')}>{t('common.new')}</Button>}

        </PageHeader>

        <PageContainer>
            <ListingTable<ElementListDto>
                columns={columns}
                rows={elements}
                order={tableData.order}
                hiddenColumns={tableData.hiddenColumns}
                columnsWidths={tableData.columnsWidth}
                onOrder={onOrder}
                onFilter={onFilter}
                onClearFilters={onClearFilters}
                onRenderAction={canWrite ? renderAction : undefined}
                onRowClick={(row) => navigateTo('details', row.id)}
                infiniteScroll={{
                    isLoading,
                    hasMore,
                    onLoadMore,
                }}
                onRowId={r => r.id}
                scrollToRowId={scrollToRowId}
                onColResize={(w) => setTableData(d => ({ ...d, columnsWidth: w }))}
            />
        </PageContainer>

        <ListingTableSidebar
            columns={columns.filter(x => x.field !== 'priceToUpdate')}
            hiddenColumns={tableData.hiddenColumns}
            onChangeHiddenColumns={(hc) => setTableData(d => ({ ...d, hiddenColumns: hc }))}
            onChangeColumnsOrder={(oc) => setTableData(d => ({ ...d, columnsOrder: oc }))}
        />
        <ConfirmDeleteModal
            itemName={elementToDelete?.name}
            isOpen={showConfirmDeleteModal}
            onClose={(result) => onDelete(result)}
        />

        <UpdatePricesModal
            isOpen={showUpdatePricesModal}
            total={allElementsToUpdate.length}
            onClose={(result) => onUpdatePricesModalClose(result)}
        />
        <InformationModal
            title={t('common.confirmation')}
            message={t('elements.prices_update.total_updated_prices', { total: totalElementsUpdated })}
            isOpen={showConfirmUpdatePricesModal}
            onClose={() => onConfirmUpdatePricesModalClose()}
        />
    </ScreenTitle>)
}

export default ElementsListScreen;
