import { BudgetLineDto } from 'api/budgets/models/BudgetDto';
import styles from './BudgetLinesTable.module.scss';
import utilsStyles from '../Utils.module.scss';
import { useTranslation } from 'react-i18next';
import BudgetLinesList from '../linesList/BudgetLinesList';
import { useEffect, useRef, useState } from 'react';
import useSticky from 'common/hooks/useSticky';
import { BUDGET_LINE_ID_PREFIX } from '../line/BudgetLine';
import { LinesTableColumn } from '../utils';
import BudgetLineColumn from '../column/BudgetLineColumn';
import { useSelector, useDispatch } from 'react-redux';
import { Reducers } from 'store/types';
import { setSelectedLineKey } from 'store/budget/action';
import InformationModal from 'common/components/modal/informationModal/InformationModal';
import InfoIcon from 'common/components/infoIcon/InfoIcon';
import store from 'store/store';

interface Props {
    disabled: boolean;
}

function BudgetLinesTable({ disabled }: Props): JSX.Element | null {
    const { t } = useTranslation();
    const selectedLineKey = useSelector<Reducers, string | null>(x => x.budget.selectedLineKey);
    const dispatch = useDispatch();
    const { ref: headerRef, stickyData } = useSticky<HTMLDivElement>();
    const containerRef = useRef<HTMLDivElement>(null);
    const lines = useSelector<Reducers, BudgetLineDto[]>(x => x.budget.lines);
    const [currentInfoTooltip, setCurrentInfoTooltip] = useState<string | null>(null);

    const onClickBody = (ev: MouseEvent) => {
        if (ev && ev.target) {
            const target = ev.target as HTMLElement;
            const targetId = target.id;

            if (targetId.includes('react-select-')) {
                return;
            }
        }

        if (store.getState().budget.selectedLineKey) {
            dispatch(setSelectedLineKey(null));
        }
    }

    const scrollToLine = (lineKey: string) => {
        const lineElement = document.getElementById(BUDGET_LINE_ID_PREFIX + lineKey);
        if (!lineElement) {
            return;
        }

        const scrollToOptions = {
            block: 'start',
            behavior: 'smooth'
        };
        lineElement.scrollIntoView(scrollToOptions as unknown as ScrollToOptions);
    }

    useEffect(() => {
        document.body.addEventListener('click', onClickBody);

        return function cleanup() {
            window.removeEventListener('click', onClickBody);
        }
    }, []);

    useEffect(() => {
        if (selectedLineKey) {
            scrollToLine(selectedLineKey);
        }
    }, [selectedLineKey])

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.table}>
                <div className={`${styles.header} ${stickyData.topIsSticky ? styles.headerSticky : ''}`} ref={headerRef}>
                    <BudgetLineColumn column={LinesTableColumn.DESIGNATION} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.designationColumn}`}>
                        {t('budgets.budget.lines.header.designation')}
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.QUANTITY} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.quantityColumn}`}>
                        {t('budgets.budget.lines.header.quantity')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.quantity_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.UNIT} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.unityColumn}`}>
                        {t('budgets.budget.lines.header.unity')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.unity_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.GROSS_UNIT_PRICE} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.grossUnityColumn}`}>
                        {t('budgets.budget.lines.header.gross_unit_price')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.gross_unit_price_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.MARGIN} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.marginColumn}`}>
                        {t('budgets.budget.lines.header.margin')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.margin_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.DISCOUNT} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.discountColumn}`}>
                        {t('budgets.budget.lines.header.discount')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.discount_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.UNIT_PRICE} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.pricePerUnityColumn}`}>
                        {t('budgets.budget.lines.header.price_per_unity')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.price_per_unity_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.UNIT_PRICE_WITH_CHILDREN} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.unitPriceWithChildrenColumn}`}>
                        {t('budgets.budget.lines.header.unit_price_with_children')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.unit_price_with_children_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TOTAL_EXCLUDING_TAX} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.totalWithoutTaxColumn}`}>
                        {t('budgets.budget.lines.header.total_without_tax')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.total_without_tax_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TOTAL_UNIT_EXCLUDING_TAX} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.totalWithoutTaxColumn}`}>
                        {t('budgets.budget.lines.header.total_unit_without_tax')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.total_unit_without_tax_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TAX} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.taxColumn}`}>
                        {t('budgets.budget.lines.header.tax')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.tax_tooltip'))} />
                    </BudgetLineColumn>
                    <BudgetLineColumn column={LinesTableColumn.TOTAL_INCLUDING_TAX} className={`${styles.column} ${utilsStyles.column} ${utilsStyles.totalWithTaxColumn}`}>
                        {t('budgets.budget.lines.header.total_with_tax')}
                        <InfoIcon onClick={() => setCurrentInfoTooltip(t('budgets.budget.lines.header.total_with_tax_tooltip'))} />
                    </BudgetLineColumn>
                </div>
                <div className={styles.body}>
                    <BudgetLinesList
                        lines={lines}
                        disabled={disabled}
                    />
                </div>
            </div>

            <InformationModal
                message={currentInfoTooltip || ''}
                onClose={() => setCurrentInfoTooltip(null)}
                isOpen={Boolean(currentInfoTooltip)}
            />
        </div>
    );
}

export default BudgetLinesTable;
