import { FilterData } from './models';
import styles from './ListingTable.module.scss';
import TextInput from '../textInput/TextInput';
import SelectInput from '../selectInput/SelectInput';
import DateInput from '../dateInput/DateInput';
import MoneyInput from '../moneyInput/MoneyInput';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import moment from 'moment';
import CheckInput from '../checkInput/CheckInput';
import { OptionTitleSubTitle } from '../searchableSelectInput/optionsFormats/OptionTitleSubTitle';

interface ColumnFilterProps {
    filter: FilterData;
    onFilter: (filterData: FilterData) => void;
}

export const ColumnFilter = ({ filter, onFilter }: ColumnFilterProps) => {
    const [tempValue, setTempValue] = useState<any>(filter.value);

    const debounced = useDebouncedCallback(
        (value) => {
            onFilter(value);
        },
        1000,
    );

    const onChange = (filterData: FilterData, forceUpdate = false) => {
        if (filterData.type === 'date-range') {
            if (filterData.value?.start) {
                filterData.value.start = moment(filterData.value.start).startOf('day').toDate(); // Ajusta para meia-noite do dia selecionado
            }
            if (filterData.value?.end) {
                filterData.value.end = moment(filterData.value.end).endOf('day').toDate(); // Ajusta para 23:59:59 do dia selecionado
            }
        }

        setTempValue(filterData.value);

        if (filterData.type === 'dropdown' || filterData.type === 'checkbox' || forceUpdate) {
            onFilter(filterData);
        } else {
            debounced(filterData);
        }
    }

    useNoInitialEffect(() => {
        if (filter.value !== tempValue) {
            setTempValue(filter.value);
        }
    }, [filter.value]);

    return (
        <div className={styles.filterColumn}>
            {filter.type === 'text' && (
                <TextInput
                    value={tempValue}
                    placeholder={filter.placeholder}
                    onChange={(e) => onChange({ ...filter, value: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            debounced.flush();
                            onChange({ ...filter, value: (e.target as any).value }, true)
                        }
                    }}
                />
            )}
            {filter.type === 'dropdown' && (
                <SelectInput
                    value={tempValue}
                    options={filter.options}
                    onChange={(value) => onChange({ ...filter, value })}
                    formatOptionLabel={OptionTitleSubTitle}
                />
            )}
            {filter.type === 'date' && (
                <DateInput
                    value={tempValue}
                    onChange={(value) => onChange({ ...filter, value })}
                />
            )}
            {filter.type === 'money' && (
                <MoneyInput
                    value={tempValue}
                    unitPrefix={filter.unitPrefix}
                    onChange={(value) => onChange({ ...filter, value })}
                    hideUnitsDrop={filter.unitPrefix === ''}
                />
            )}
            {filter.type === 'money-range' && (
                <>
                    <MoneyInput
                        value={tempValue?.start}
                        onChange={(value) => onChange({ ...filter, value: { ...(tempValue ?? { start: undefined, end: undefined }), start: value } })}
                        isGroupStart
                        unitPrefix={filter.unitPrefix}
                        hideUnitsDrop={filter.unitPrefix === ''}
                    />
                    <MoneyInput
                        value={tempValue?.end}
                        onChange={(value) => onChange({ ...filter, value: { ...(tempValue ?? { start: undefined, end: undefined }), end: value } })}
                        isGroupEnd
                        unitPrefix={filter.unitPrefix}
                        hideUnitsDrop={filter.unitPrefix === ''}
                    />
                </>
            )}
            {filter.type === 'date-range' && (
                <>
                    <DateInput
                        value={tempValue?.start}
                        onChange={(value) => onChange({ ...filter, value: { ...(tempValue ?? { start: undefined, end: undefined }), start: value } })}
                        isGroupStart
                        maxDate={tempValue?.end}
                    />
                    <DateInput
                        value={tempValue?.end}
                        onChange={(value) => onChange({ ...filter, value: { ...(tempValue ?? { start: undefined, end: undefined }), end: value } })}
                        isGroupEnd
                        minDate={tempValue?.start}
                    />
                </>
            )}
            {filter.type === 'checkbox' && (
                <CheckInput
                    value={tempValue}
                    checked={tempValue}
                    onChange={(e: any) => {
                        onChange({ ...filter, value: e.target.checked })
                    }}

                />
            )}
        </div>
    );
}
