import Http from 'common/services/Http';
import { BudgetDto, BudgetToPdfDto } from './models/BudgetDto';
import IdVersion from 'api/common/types/IdVersion';
import { Paged } from 'api/common/types/Page';
import { BudgetsSearchCriteria } from './models/BudgetsSearchCriteria';
import { BudgetLineParameterByElementDto, BudgetLineParameterDto } from './models/BudgetLineParameterDto';
import { BudgetWorkDataDto } from './models/BudgetWorkDataDto';
import { BudgetHistoriesDto } from './models/BudgetHistoriesDto';
import BudgetGenerateReferenceViewModel from './models/BudgetGenerateReferenceViewModel';

class BudgetsService {
    public getList(criteria: BudgetsSearchCriteria) {
        return Http.get<Paged<BudgetDto>>('budgets', criteria);
    }

    public getById(id: string, isDetails: boolean) {
        return Http.get<BudgetDto>('budgets/' + id + '/' + isDetails);
    }

    public getBudgetHistoryByVersion(budgetId: string, version: string) {
        return Http.get<BudgetDto>('budgetHistories', { budgetId, version });
    }

    public getHistoriesList(budgetId: string) {
        return Http.get<BudgetHistoriesDto[]>('budgetHistories/list-histories/' + budgetId);
    }

    public create(model: BudgetDto): Promise<string> {
        return Http.post('budgets', model);
    }

    public update(model: BudgetDto) {
        return Http.put('budgets', model);
    }

    public remove(model: IdVersion) {
        return Http.put('budgets/deactivate', model);
    }

    public getElementParameters(elementId: string,getChildren: boolean) {
        return Http.get<BudgetLineParameterByElementDto>('budgets/element-parameters/' + elementId + '/' + getChildren);
    }

    public getWorkData(workId: string) {
        return Http.get<BudgetWorkDataDto>('budgets/work-data/' + workId);
    }

    public printBudget(model: BudgetToPdfDto): Promise<Blob> {
        // 'download' is just for the path,it is not used for anything
        return Http.getWithConfig('budgets/print-budget/download', model, { responseType: 'blob' });
    }

    public getPrintBudgetUrl(model: BudgetToPdfDto, name: string): string {
        return Http.createUrl('budgets/print-budget/' + name, model);
    }

    public generateReference(model: BudgetGenerateReferenceViewModel) {
        return Http.get<{ reference: string }>('budgets/generate-reference', model);
    }

    public downloadBudget(file: Blob, fileName: string) {
        const url = URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.download = `Devis_${fileName}.pdf`;
        anchor.href = url;
        anchor.target = '_blank'
        anchor.click();
    }
}

export default new BudgetsService();
