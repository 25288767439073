import { BaseModule } from 'quill-image-resize-module-react/src/modules/BaseModule';
import { ReactComponent as IconAlignLeft } from 'assets/svg/align-left.svg';
import { ReactComponent as IconAlignCenter } from 'assets/svg/align-center.svg';
import { ReactComponent as IconAlignRight } from 'assets/svg/align-right.svg';
import ReactDOMServer from 'react-dom/server';

type ButtonType = 'resize' | 'alignment';

interface SizeItem {
    name?: string;
    icon?: any;
    apply: () => void;
    isApplied: () => boolean;
    type: ButtonType;
}

let Parchment: any = {};
let WidthStyle: any = {};
let FloatStyle: any = {};
let MarginStyle: any = {};
let DisplayStyle: any = {};

export class ImageResizePercentageModule extends BaseModule {
    alignments: SizeItem[] = [];

    onCreate = (parchment: any) => {
        Parchment = parchment;
        WidthStyle = new Parchment.Attributor.Style('width', 'width');
        FloatStyle = new Parchment.Attributor.Style('float', 'float');
        MarginStyle = new Parchment.Attributor.Style('margin', 'margin');
        DisplayStyle = new Parchment.Attributor.Style('display', 'display');

        // Setup Toolbar
        this.toolbar = document.createElement('div');
        Object.assign(this.toolbar.style, this.options.imageResizeStyles);
        this.overlay.appendChild(this.toolbar);

        // Setup Buttons
        this._defineAlignments();
        this._addToolbarButtons();
    };

    // The toolbar and its children will be destroyed when the overlay is removed
    onDestroy = () => { };

    // Nothing to update on drag because we are are positioned relative to the overlay
    onUpdate = () => { };

    _defineAlignments = () => {
        this.alignments = [
            {
                icon: ReactDOMServer.renderToString(<IconAlignLeft />),
                apply: () => {
                    DisplayStyle.add(this.img, 'block');
                    FloatStyle.remove(this.img);
                    MarginStyle.add(this.img, '0 0 0 0');
                },
                isApplied: () => FloatStyle.value(this.img) === 'left',
                type: 'alignment',
            },
            {
                icon: ReactDOMServer.renderToString(<IconAlignCenter />),
                apply: () => {
                    DisplayStyle.add(this.img, 'block');
                    FloatStyle.remove(this.img);
                    MarginStyle.add(this.img, 'auto');
                },
                isApplied: () => MarginStyle.value(this.img) === 'auto',
                type: 'alignment',
            },
            {
                icon: ReactDOMServer.renderToString(<IconAlignRight />),
                apply: () => {
                    DisplayStyle.add(this.img, 'block');
                    FloatStyle.remove(this.img);
                    MarginStyle.add(this.img, '0 0 0 auto');
                },
                isApplied: () => FloatStyle.value(this.img) === 'right',
                type: 'alignment',
            },
            {
                name: '25%',
                apply: () => {
                    WidthStyle.add(this.img, '25%');
                },
                isApplied: () => WidthStyle.value(this.img) === '25%',
                type: 'resize',
            },
            {
                name: '50%',
                apply: () => {
                    WidthStyle.add(this.img, '50%');
                },
                isApplied: () => WidthStyle.value(this.img) === '50%',
                type: 'resize',
            },
            {
                name: '100%',
                apply: () => {
                    WidthStyle.add(this.img, '100%');
                },
                isApplied: () => WidthStyle.value(this.img) === '100%',
                type: 'resize',
            },
        ];
    };

    _addToolbarButtons = () => {
        const buttons: { item: SizeItem, element: HTMLSpanElement }[] = [];
        this.alignments.forEach((alignment, idx) => {
            const button = document.createElement('span');
            buttons.push({
                item: alignment,
                element: button,
            });
            button.innerHTML = alignment.icon ? alignment.icon : `<span>${alignment.name}</span>`;
            button.addEventListener('click', () => {
                if (alignment.isApplied() && alignment.type === 'resize') {
                    return;
                }

                // deselect all buttons
                buttons.filter(x => x.item.type === alignment.type).forEach(button => {
                    button.element.style.filter = '';
                });

                if (alignment.isApplied()) {
                    // If applied, unapply
                    WidthStyle.remove(this.img);
                    FloatStyle.remove(this.img);
                    MarginStyle.remove(this.img);
                    DisplayStyle.remove(this.img);
                } else {
                    // otherwise, select button and apply
                    this._selectButton(button);
                    alignment.apply();
                }
                // image may change position; redraw drag handles
                this.requestUpdate();
            });
            Object.assign(button.style, this.options.imageResizeButtonStyles);
            if (idx > 0) {
                button.style.borderLeftWidth = '0';
            }

            if (button.children[0]) {
                Object.assign((button.children[0] as any).style, this.options.toolbarButtonSvgStyles);
            }

            if (alignment.isApplied()) {
                // select button if previously applied
                this._selectButton(button);
            }
            this.toolbar.appendChild(button);
        });
    };

    _selectButton = (button: HTMLSpanElement) => {
        button.style.filter = 'invert(20%)';
    };
}
