import { Action, AuthenticationState, SET_AUTHENTICATION, SET_PROFILE, SET_TOKEN, UPDATE_REFRESH_TOKEN } from './type';

const initialState: AuthenticationState = {
    isAuthenticated: false,
    profile: null,
    token: null,
    refreshToken: 1
};

function reducer (state: AuthenticationState = initialState, action: Action): AuthenticationState {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return {
                ...state,
                isAuthenticated: Boolean(action.profile),
                profile: action.profile,
                token: action.token
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.token
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.profile
            };
        case UPDATE_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: state.refreshToken + 1
            };
        default:
            return state;
    }
}

export default reducer;
