import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import styles from './RichTextInput.module.scss';
import React, { Ref, useEffect, useRef, useState } from 'react';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import ImageResize from 'quill-image-resize-module-react/src/ImageResize';
import { ImageDrop } from 'quill-image-drop-module';
import { ImageResizePercentageModule } from './ImageResizePercentageModule';
const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];

(window as any).Quill = Quill;

const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;

Quill.register(Size, true);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

const ImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    // 'width',
    'style'
];

ImageFormat.create = function create(value: any): HTMLElement {
    const node = document.createElement('img');
    if (value) {
        node.setAttribute('src', value);
    }
    node.setAttribute('style', 'width: 50%;');
    return node;
};

ImageFormat.formats = function formats(domNode: any): any {
    return ImageFormatAttributesList.reduce(
        (formats, attribute) => {
            if (domNode.hasAttribute(attribute)) {
                (formats as any)[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        },
        {}
    );
};

ImageFormat.prototype.format = function format(name: string, value: any): void {
    if (ImageFormatAttributesList.includes(name)) {
        if (value) {
            this.domNode.setAttribute(name, value);
        } else {
            this.domNode.removeAttribute(name);
        }
    } else {
        this.super.format(name, value);
    }
};

Quill.register(ImageFormat, true);

const colors: Array<string> = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc',
    '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
    '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2',
    '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', '#99AA9C'
];

const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'ordered', 'indent',
    'link', 'image',
    'align', 'color', 'background', 'size',
    'clean', 'style', 'height', 'width',
    'header',
    'font'
];

interface RichTextInputProps {
    value: string | null | undefined;
    hasError?: boolean;
    disabled?: boolean;
    placeholder?: string;
    rows?: number;
    isInline?: boolean;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
}

const RichTextInputPlain = ({ value, disabled, hasError, isInline, placeholder, onChange, onBlur }: RichTextInputProps, ref: Ref<ReactQuill>) => {
    const [refreshKey, setRefreshKey] = useState(0);
    const modules = {
        imageResize: !disabled
            ? {
                parchment: Quill.import('parchment'),
                displaySize: true,
                modules: [ImageResizePercentageModule],
                imageResizeStyles: {
                    position: 'absolute',
                    top: '-12px',
                    right: '0',
                    left: '0',
                    height: '0',
                    minWidth: '100px',
                    font: '12px/1.0 Arial, Helvetica, sans-serif',
                    textAlign: 'center',
                    color: '#333',
                    boxSizing: 'border-box',
                    cursor: 'default',
                    display: 'flex',
                    justifyContent: 'center'
                },
                imageResizeButtonStyles: {
                    fontWeight: 'bold',
                    fontSize: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '34px',
                    height: '24px',
                    background: 'white',
                    border: '1px solid #999',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                },
                toolbarButtonSvgStyles: {
                    fill: '#444',
                    stroke: '#444',
                    strokeWidth: '2',
                    width: 20,
                },
            }
            : false,
        imageDrop: !disabled,
        toolbar: !disabled
            ? [
                [{ size: fontSizeArr }],
                ['bold', 'italic', 'underline', 'strike','image'],
                [{ color: colors }, { background: [] }],
                ['clean'],
            ]
            : false,
        clipboard: {
            matchVisual: false
        },
    };

    const [tempValue, setTempValue] = useState(value);
    const tempValueRef = useRef(value);

    const onBlurHandle = () => {
        if (onBlur) {
            onBlur(tempValue ?? '');
        }
    }

    useNoInitialEffect(() => {
        if (tempValueRef.current !== value) {
            setTempValue(value);
            setRefreshKey(y => y + 1);
        }
    }, [value])

    useEffect(() => { setRefreshKey(y => y + 1) },[disabled])

    return (
        <ReactQuill
            key={refreshKey}
            theme="snow"
            defaultValue={tempValue ?? undefined}
            onChange={(v) => {
                tempValueRef.current = v;
                setTempValue(v);
                if (onChange) {
                    onChange(v);
                }
            }}
            onBlur={onBlurHandle}
            readOnly={disabled}
            placeholder={placeholder}
            modules={modules}
            formats={formats}
            className={`${styles.quill} ${hasError ? styles.hasError : ''} ${isInline ? styles.isInline : ''}`}
            ref={ref}
            preserveWhitespace
        />
    );
};

const RichTextInput = React.forwardRef(RichTextInputPlain);

export default RichTextInput;

type InputControllerProps<T extends FieldValues> = Omit<RichTextInputProps, 'value'> & UseControllerProps<T>;

export const RichInputController = <T extends FieldValues>(props: InputControllerProps<T>) => {
    const { field } = useController({ ...props, disabled: false });
    return (
        <RichTextInput
            {...props}
            value={field.value ?? ''}
            onChange={(e) => { // use onBlur instead due to safari issues
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
};
