export enum ParameterSectionType {
    SECTION = 'SECTION',
    PARAMETER = 'PARAMETER',
}

export enum ParameterSectionParameterType {
    IMAGES = 'IMAGES',
    NUMBER = 'NUMBER',
    NUMBER_MIN_MAX = 'NUMBER_MIN_MAX',
    PRODUCTS = 'PRODUCTS',
    TEXT = 'TEXT',
    QUANTITY = 'QUANTITY'
}

export interface ParameterSectionBaseModel {
    id?: string;
    key: string;
    title?: string | null;
    type: ParameterSectionType;
    unitId?: string | null;
    unitSymbol?: string | null;
    familyId?: string;
    parameterType?: ParameterSectionParameterType | null;
    parameterName?: string | null;
    parameterFamilyId?: string | null;
    parameterIsRequired?: boolean;
    visibleOnlyInBudgets?: boolean;
    isOpen: boolean;
    closed: boolean;
    parentKey: string | null;
    relativeKey?: string | null;
}
